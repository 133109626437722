/** @jsxImportSource @emotion/react */

import React, { FC } from 'react';
import { css } from '@emotion/react';

import ValueUpdateCallback from '../types/ValueUpdateCallback';

const fieldsetStyle = css({
    marginTop: '1rem',
    padding: 0,
    border: 0
});
const labelStyle = css({
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
});
const textareaStyle = css({
    display: 'block',
    padding: '0.5rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    fontFamily: 'inherit',
    height: '12rem',
    marginTop: '0.3rem',
    borderRadius: 5,
    border: 1,
    width: 'calc(100% - 1rem)'
});

const TextareaFieldset: FC<{label?: string, value?: string, onUpdate?: ValueUpdateCallback}> = ({ label, value, onUpdate }) => (
    <fieldset className="TextareaFieldset" css={fieldsetStyle}>
        <label css={labelStyle}>
            {label}
            <textarea css={textareaStyle} onChange={e => { if(onUpdate) { onUpdate(e.target.value) } }} value={value} />
        </label>
    </fieldset>
);

export default TextareaFieldset;
