/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import '@csstools/normalize.css';

import Body from './organisms/Body';
import Form from './organisms/Form';
import Footer from './atoms/Footer';
import Header from './atoms/Header';
import Section from './atoms/Section';
import Tips from './atoms/Tips';
import Visualizer from './organisms/Visualizer';

import './App.css';

const style = css({
  background: '#282828',
  color: '#fff',
  minHeight: '100vh'
});

function validate(x: string): string {
  return x
    .toUpperCase()
    .replaceAll(',', "\n")
    .replaceAll(' ', "\n")
    .replace(/[^A-Z\n]/g, '');
}

function App() {
  const [puzzleNumber, setPuzzleNumber] = useState('100');
  const [answerWord, setAnswerWord] = useState('EEEEE');
  const [guesses, setGuesses] = useState("AAAAA\nBBBBB\nABCDE\nEEEEE");
  const [themeName, setThemeName] = useState('light');

  return (
    <div className="App" css={style}>
      <Header />
      <Body>
        <Section gridArea='form'>
          <Form puzzleNumber={puzzleNumber}
            onPuzzleNumberChange={(x: string) => setPuzzleNumber(x)}
            answerWord={answerWord}
            onAnswerWordChange={(x: string) => setAnswerWord(validate(x))}
            guesses={guesses}
            onGuessesChange={(x: string) => setGuesses(validate(x))}
            themeName={themeName}
            onThemeNameChange={setThemeName}
            />
        </Section>
        <Section gridArea='visualizer'>
          <Visualizer puzzleNumber={puzzleNumber}
            answerWord={answerWord}
            guesses={guesses}
            themeName={themeName}
            />
        </Section>
        <Section gridArea='help'>
          <Tips />
        </Section>
      </Body>
      <Footer />
    </div>
  );
}

export default App;
