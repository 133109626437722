/** @jsxImportSource @emotion/react */

import React, { FC } from 'react';
import { css } from '@emotion/react';

import ValueUpdateCallback from '../types/ValueUpdateCallback';
import InputFieldset from '../atoms/InputFieldset';
import Select from '../atoms/Select';
import TextareaFieldset from '../atoms/TextareaFieldset';

const h2Style = css({
    fontSize: '1.5rem',
    margin: '0 0 0.5rem'
});
const mutedText = css({
    color: '#999',
    marginLeft: '0.5em'
});

const Form: FC<{
    puzzleNumber: string, onPuzzleNumberChange: ValueUpdateCallback,
    answerWord: string, onAnswerWordChange: ValueUpdateCallback,
    guesses: string, onGuessesChange: ValueUpdateCallback,
    themeName: string, onThemeNameChange: ValueUpdateCallback
    }> = ({
        puzzleNumber, onPuzzleNumberChange,
        answerWord, onAnswerWordChange,
        guesses, onGuessesChange,
        themeName, onThemeNameChange
    }) => (
    <section className="Form">
        <h2 css={h2Style}>Your Game</h2>
        <p>
            Enter the game information and get a live visualization.
            <a href="#tips" css={mutedText}>See tips.</a>
        </p>
        <InputFieldset label='Puzzle Number' value={puzzleNumber} onUpdate={onPuzzleNumberChange} />
        <InputFieldset label='Answer Word' value={answerWord} onUpdate={onAnswerWordChange} />
        <TextareaFieldset label='Your Guesses' value={guesses} onUpdate={onGuessesChange} />
        <Select label='Theme' selectedValue={themeName} onUpdate={onThemeNameChange} />
    </section>
);

export default Form;
