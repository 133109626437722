/** @jsxImportSource @emotion/react */

import React, { FC } from 'react';
import { css } from '@emotion/react';

import ValueUpdateCallback from '../types/ValueUpdateCallback';

const fieldsetStyle = css({
    marginTop: '1rem',
    padding: 0,
    border: 0
});
const labelStyle = css({
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
});
const inputStyle = css({
    display: 'block',
    padding: '0.5rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: '0.3rem',
    borderRadius: 5,
    border: 1,
    width: 'calc(100% - 1rem)'
});

const InputFieldset: FC<{label?: string, value?: string, onUpdate?: ValueUpdateCallback, maxLength?: number}> = ({ label, value, onUpdate, maxLength }) => (
    <fieldset className="InputFieldset" css={fieldsetStyle}>
        <label css={labelStyle}>
            {label}
            <input css={inputStyle} value={value} onChange={e => { if(onUpdate) { onUpdate(e.target.value) } }} maxLength={maxLength || 5}></input>
        </label>
    </fieldset>
);

export default InputFieldset;
