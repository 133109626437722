//

interface Theme {
    title: string,
    correct: string,
    in_word: string,
    miss: string
};

const Themes: {[key: string]: Theme} = {
    cats: {
        title: 'Cats',
        correct: '😻',
        in_word: '😼',
        miss: '🙀'
    },
    circles: {
        title: 'Circles',
        correct: '🟢',
        in_word: '🟡',
        miss: '⚫️'
    },
    dark: {
        title: 'Dark',
        correct: '🟩',
        in_word: '🟨',
        miss: '⬛️'
    },
    hearts: {
        title: 'Hearts',
        correct: '💚',
        in_word: '💛',
        miss: '💔'
    },
    light: {
        title: 'Light',
        correct: '🟩',
        in_word: '🟨',
        miss: '⬜️'
    },
    marks: {
        title: 'Marks',
        correct: '✅',
        in_word: '❔',
        miss: '❌'
      
    },
    nature: {
        title: 'Nature',
        correct: '🌸',
        in_word: '🌱',
        miss: '🪨'
    },
    smileys: {
        title: 'Smileys',
        correct: '😁',
        in_word: '🤔',
        miss: '🥶'
    },
    space: {
        title: 'Space',
        correct: '⭐️',
        in_word: '🌞',
        miss: '🌚'
    },
    tropical: {
        title: 'Tropical',
        correct: '🍍',
        in_word: '🏝',
        miss: '🌊'
    },
    uh_yeah: {
        title: 'Uh, yeah',
        correct: '🤓',
        in_word: '🤡',
        miss: '💩'
    },
};

export default Themes;
