/** @jsxImportSource @emotion/react */

import React, { FC } from 'react';
import { css } from '@emotion/react';

const style = css({
    border: '1px solid #777',
    padding: '1rem'
});

const Section: FC<{ gridArea: string }> = ({ gridArea, children }) => (
    <section className="Section" css={style} style={{ gridArea: gridArea }}>
        {children}
    </section>
);

export default Section;
