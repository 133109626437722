/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import logo from '../assets/formatle-icon-transparent.png';
import TweetButton from './TweetButton';

const style = css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    color: '#fff',
    background: '#000',
    textAlign: 'left',
    padding: '0.5rem 3rem',
    fontWeight: 'bold'
});
const logoStyle = css({
  height: '1.5rem',
  display: 'block',
  position: 'absolute',
  marginTop: '-0.2rem'
});
const h1Style = css({
    fontSize: '1rem',
    display: 'inline',
    marginLeft: '2rem'
});
const taglineStyle = css({
    color: '#888',
    fontStyle: 'italic',
    fontWeight: 'normal',
    marginLeft: '2rem'
});
const tweetContainerStyle = css({
  position: 'absolute',
  top: '0.5rem',
  right: '3rem'
});

function Header() {
  return (
    <header className="Header" css={style}>
        <img src={logo} alt="Formatle logo icon" css={logoStyle} />
        <h1 css={h1Style}>Formatle</h1>
        <span css={taglineStyle}>Wordle Formatter</span>
        <div css={tweetContainerStyle}>
          <TweetButton />
        </div>
    </header>
  );
}

export default Header;
