/** @jsxImportSource @emotion/react */

import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/react';

import Themes from '../themes/Themes';

const style = css({
});
const h2Style = css({
    fontSize: '1.35rem',
    margin: '0 0 0.25rem 0',
    letterSpacing: '0.1rem'
});
const visibleStyle = css({
    fontSize: '2em',
    lineHeight: '1.25',
    letterSpacing: '0.3rem'
});
const gridStyle = css({
    textShadow: '0px 2px 4px #000'
});
const altStyle = css({
    marginTop: '2rem'
});
const copyButtonsHeading = css({
    color: '#aaa',
    marginRight: '0.5rem'
});
const copyButtonStyle = css({
    background: '#fc4',
    border: '3px solid #000',
    display: 'inline-block',
    margin: '0 0.5rem 1rem 0',
    fontFamily: 'inherit',
    padding: '5px 10px',
    color: '#000',
    fontWeight: 'bold',
    borderRadius: 5,
    ':active': {
        backgroundColor: '#fff'
    }
});
const a11yOnly = css({
    position: 'absolute',
    left: -2000
});

let _CARDINAL: string[] = ['-', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
let _MAX_GUESSES: number = 6;

interface KeyValue {
    [key: string]: boolean
}

interface LetterTuple {
    guessLetter: string;
    answerLetter?: string;

    isGuessLetterInWord: boolean;
    isGuessLetterCorrect: boolean;
}

function checkForCorrectWord(resultMap: LetterTuple[][]): boolean {
    let hasCorrectWord: boolean = false;
    resultMap.forEach( wordTuple => {
        if (checkTupleIsAllCorrect(wordTuple)) {
        hasCorrectWord = true;
        }
    });

    return hasCorrectWord;
}

function checkTupleIsAllCorrect(wordTuple: LetterTuple[]): boolean {
    let allCorrect: boolean = true;
    wordTuple.forEach( letterTuple => {
        if (!letterTuple.isGuessLetterCorrect) {
        allCorrect = false;
        }
    });

    return allCorrect;
}

function generateAltText(resultMap: LetterTuple[][]): string {
    let isCorrectWordInResult: boolean = checkForCorrectWord(resultMap);
    let numberGuessesDescription = generateNumberGuessesDescription(resultMap.length);

    if (!isCorrectWordInResult) {
    return `Wordle grid with no correct guess after ${numberGuessesDescription}.`;
    }

    return `Wordle guessed correctly in ${numberGuessesDescription}: ${resultMap.map((wordTuple, idx) => generateAltTextForWordTuple(wordTuple, idx)).join(', ')}.`;
}

function generateAltTextForWordTuple(wordTuple: LetterTuple[], index: number): string {
    let guessDescription: string;
    if (checkTupleIsAllCorrect(wordTuple)) {
        guessDescription = 'is the correct word'
    } else {
        let numberInWord: number = 0;
        let numberCorrect: number = 0;
        wordTuple.forEach(letterTuple => {
        if (letterTuple.isGuessLetterCorrect) {
            numberCorrect++;
        } else if (letterTuple.isGuessLetterInWord) {
            numberInWord++;
        }
        });

        if (numberInWord > 0) {
        if (numberCorrect > 0) {
            guessDescription = `${numberCorrect} correct ${numberInWord} in wrong place`;
        } else {
            guessDescription = `${numberInWord} in wrong place`;
        }
        } else {
        if (numberCorrect > 0) {
            guessDescription = `${numberCorrect} correct`;
        } else {
            guessDescription = `no letters match`;
        }
        }
    }

    return `${_CARDINAL[index + 1]} guess ${guessDescription}`;
}

function generateNumberGuessesDescription(numberGuesses: number): string {
    return numberGuesses === 1
        ? '1 guess'
        : `${numberGuesses} guesses`;
}

function generateResultMap(answerWord: string, guesses: string[], comparisonMap: KeyValue): LetterTuple[][] {
    return guesses.map(guess => getLetterTuples(guess, answerWord, comparisonMap))
}

function getCharactersFromString(s: string): string[] {
    let acc: string[] = [];
    for (let i: number = 0; i<s.length; i++) {
        acc.push(s.charAt(i).toUpperCase());
    }
    return acc;
}

function getComparisonMap(word: string) {
    let map: KeyValue = {};
    getCharactersFromString(word).forEach(
        character => map[character] = true
    );
    return map;
}

function getLetterTuples(guessWord: string, answerWord: string, comparisonMap: KeyValue): LetterTuple[] {
    let letterTuples: LetterTuple[] = [];

    getCharactersFromString(guessWord).forEach(char => {
        letterTuples.push({
        guessLetter:char,
        isGuessLetterInWord: comparisonMap[char] !== undefined,
        isGuessLetterCorrect: false
        });
    })

    getCharactersFromString(answerWord).forEach((char, idx) => {
        if (idx >= letterTuples.length) { return; }

        letterTuples[idx].answerLetter = char;

        if (letterTuples[idx].guessLetter === char) {
        letterTuples[idx].isGuessLetterCorrect = true;
        }
    })

    return letterTuples;
}

function mapGuess(letterTuple: LetterTuple, themeName: string): string {
    let theme = Themes[themeName];
    if (letterTuple.isGuessLetterCorrect) { return theme['correct']; }
    if (letterTuple.isGuessLetterInWord) { return theme['in_word']; }
    return theme['miss'];
}

function e(s: string, blank: string): string {
    return s.match(/^\s*$/) ? blank : s;
}

function renderGrid(resultMap: LetterTuple[][], themeName: string): ReactNode {
    let output: ReactNode[] = [];

    resultMap.forEach((guessTupleSet, idx) => {
        output.push(<span key={`grid-row-${idx}`}>
            {guessTupleSet.map(letterTuple => mapGuess(letterTuple, themeName)).join('')}<br />
        </span>);
    });

    return output;
}

function renderGridText(resultMap: LetterTuple[][], themeName: string): string {
    let output: string[] = []

    resultMap.forEach(guessTupleSet => {
        output.push(guessTupleSet.map(letterTuple => mapGuess(letterTuple, themeName)).join(''));
    });

    return output.join("\n");
}

function validateGuesses(guesses: string): string[] {
    let validGuesses: string[] = [];

    guesses
        .split(/\n/)
        .map((guess: string) => guess.trim())
        .forEach(guess => {
            if (guess.length === 5) {
                validGuesses.push(guess.toUpperCase());
            }
        })

    return validGuesses;
}

const Visualizer: FC<{ puzzleNumber: string, answerWord: string, guesses: string, themeName: string }> = ({
    puzzleNumber, answerWord, guesses, themeName
    }) => {
    const formattedGuesses: string[] = validateGuesses(guesses);
    const comparisonMap: KeyValue = getComparisonMap(answerWord);
    const resultMap: LetterTuple[][] = generateResultMap(answerWord, formattedGuesses, comparisonMap);

    const visualizationTitle = `Wordle ${e(puzzleNumber, '---')} ${formattedGuesses.length}/${_MAX_GUESSES}`;

    function copyAltText() {
        navigator.clipboard.writeText(
            generateAltText(resultMap)
        );
    }

    function copyTitleText() {
        navigator.clipboard.writeText(visualizationTitle);
    }

    function copyGrid() {
        navigator.clipboard.writeText(
            visualizationTitle + "\n" +
            renderGridText(resultMap, themeName) + "\n"
        );
    }

    return (<section className="Visualizer" css={style}>
        <div>
            <span css={copyButtonsHeading}>Copy:</span>
            <button onClick={copyGrid} css={copyButtonStyle} title="Copy the formatted emoji grid">
                <span css={a11yOnly}>The full</span> Grid
            </button>
            <button onClick={copyTitleText} css={copyButtonStyle} title="Copy the short title of this game">
                <span css={a11yOnly}>Just the</span> Title
            </button>
            <button onClick={copyAltText} css={copyButtonStyle} title="Copy the alt text for screenshots">
                <span css={a11yOnly}>The</span> Alt <span css={a11yOnly}>Text</span>
            </button>
        </div>
        <div className="Visualizer__Visible" css={visibleStyle}>
            <h2 css={h2Style}>
                {visualizationTitle}
            </h2>
            <div aria-hidden title="Wordle play grid visualization" css={gridStyle}>
                {renderGrid(resultMap, themeName)}
            </div>
        </div>
        <div className="Visualizer__Alt" css={altStyle}>
            {generateAltText(resultMap)}
        </div>
    </section>);
};

export default Visualizer;
