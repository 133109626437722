/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

const style = css({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    color: '#777',
    background: '#000',
    textAlign: 'right',
    padding: 10,
    fontSize: '0.75rem'
});

function Footer() {
  return (
    <footer className="Footer" css={style}>
        2022
    </footer>
  );
}

export default Footer;
