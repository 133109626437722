/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

const style = css({
  color: '#aaa'
});
const h2Style = css({
  margin: 0
});
const liStyle = css({
  marginBottom: '0.5rem'
});
const keyStyle = css({
  display: 'inlineBlock',
  border: '1px solid #777',
  borderRadius: '4px',
  padding: '0.25em 0.7em',
  margin: '0 0.25em',
  background: 'black',
  color: 'white'
});

function Tips() {
  return (
    <section className="Tips" css={style}>
        <h2 id="tips" css={h2Style}>Tips</h2>
        <p>
            Enter in your puzzle number,
            the answer, and guesses.
            The visualization panel will
            render a grid that looks great
            in a screen shot!
        </p>
        <p>
            Below the grid is text you can
            use as "alt text" for a screenshot
            so that screen readers can 
            read a meaningful description of
            your image!
        </p>
        <ul>
          <li css={liStyle}>
            <b>Grid button:</b> Copy the grid
            title and the emoji grid to the 
            clipboard.
          </li>
          <li css={liStyle}>
            <b>Title button:</b> Copy the just
            the grid title to the clipboard.
          </li>
          <li css={liStyle}>
            <b>Alt button:</b> Copy the alt
            text description to the clipboard.
          </li>
        </ul>
        <p>
          <i>Pro tip:</i> when entering Your Guesses
          type a <span css={keyStyle} title="comma">,</span> or
          <span css={keyStyle} title="space"> </span> to move to
          the next line.
        </p>
    </section>
  );
}

export default Tips;
