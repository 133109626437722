/** @jsxImportSource @emotion/react */

import React, { FC, ReactElement } from 'react';
import { css } from '@emotion/react';

import Themes from '../themes/Themes';

import ValueUpdateCallback from '../types/ValueUpdateCallback';

const fieldsetStyle = css({
    marginTop: '1rem',
    padding: 0,
    border: 0
});
const labelStyle = css({
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
});
const selectStyle = css({
    display: 'block',
    padding: '0.5rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: '0.3rem',
    borderRadius: 5,
    border: 1,
    width: 'calc(100%)'
});

function selectOptions(selectedValue: string): ReactElement[] {
    return Object.keys(Themes).sort().map(
        k => <option value={k} selected={selectedValue === k}>{Themes[k].title}</option>
    );
}

const Select: FC<{label?: string, selectedValue?: string, onUpdate?: ValueUpdateCallback}> = ({ label, selectedValue, onUpdate }) => (
    <fieldset className="Select" css={fieldsetStyle}>
        <label css={labelStyle}>
            {label}
            <select css={selectStyle} onChange={e => { if(onUpdate) { onUpdate(e.target.value) } }}>
                {selectOptions(selectedValue || 'light')}
            </select>
        </label>
    </fieldset>
);

export default Select;
