/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

const style = css({
});

const TweetButton = () => (
    <section className="TweetButton" css={style}>
        <a href="https://twitter.com/share?text=Check%20out%20Formatle,%20a%20Wordle%20formatter." className="twitter-share-button" data-show-count="false">Tweet</a>
    </section>
);

export default TweetButton;
